<template>
    <div class="my-team">
        <div class="top">
            <span>我的邀请人</span>
            <div class="info-box">
                <img :src="superior.headimgurl" />
                <div class="info">
                    <p>
                        <span class="nickname">{{superior.nickname}}</span>
                        <span class="time">邀请时间：{{superior.create_time}}</span>
                    </p>
                    <p>姓名：{{superior.user_name}} {{superior.user_phone}}</p>
                </div>
            </div>
        </div>
        <!-- 筛选区 -->
        <div class="tab-box">
            <div class="tab">
                <div :class="['item',{'active':tab == 0}]" @click="tab = 0">手机号搜索</div>
                <div :class="['item',{'active':tab == 1}]" @click="tab = 1">姓名搜索</div>
            </div>
        </div>
        <div class="search-box flex">
            <van-search class="search" v-model="keyWords" :placeholder="'请输入' + (tab == 0?'手机号':'姓名') + '进行搜索'"
                @clear="resetKeyWords" />
            <van-button class="sbtn" color="#FF5400" @click="resetList">搜索</van-button>
        </div>
        <!-- 主内容 -->
        <div class="nav-box">
            <a href="javascript:void(0);" @click="changeTab(1)" :class="{'ac':Tab == 1}">
                <span>直属掌柜</span>
                <span>({{teamInfo.team1}})</span>
            </a>
            <a href="javascript:void(0);" @click="changeTab(2)" :class="{'ac':Tab == 2}">
                <span>间接掌柜</span>
                <span>({{teamInfo.team2}})</span>
            </a>
        </div>
        <template v-if="isLoad">
            <template v-if="list.length > 0">
                <div class="team-grid" v-for="(item, index) in list" :key="index">
                    <!-- <img :src="item.headimgurl" /> -->
                    <van-image :src="item.headimgurl" class="img"></van-image>
                    <div class="team-info">
                        <p class="nickname">
                            {{item.nickname}}
                            <span>{{ item.create_time }}</span>
                        </p>
                        <p class="name" v-if="Tab == 1">{{item.user_name}} {{item.user_phone}}</p>
                        <p class="name" v-else>{{item.user_name | noPassByName}} {{item.user_phone | noPassByMobile}}
                        </p>
                        <p class="desc">贡献：{{item.team_order}}单 | 贡献收益：￥{{item.team_profit}} | 团队：{{item.team_total}}人
                        </p>
                    </div>
                </div>
                <load-more :loadLastText="loadMore" @getMore="getMore"></load-more>
            </template>
            <no-data v-else content="这里没有数据"></no-data>
        </template>
    </div>
</template>

<script>
    import {
        getSuperior,
		getUserWork,
        getUserTeam,
    } from "@/http/api";
    export default {
        data() {
            return {
                userToken: "",
                superior: {},
                teamInfo: {
                    team1: 0,
                    team2: 0
                },
                list: [],
                tab: 0,
                Tab: 1,
                isLoad: false,
                pages: 1,
                loadMore: true,
                keyWords: "",
                searchType: 1,
                searchTypeList: [{
                        text: "手机号",
                        value: 1
                    },
                    {
                        text: "姓名",
                        value: 2
                    }
                ],
                inputType: "number",
            };
        },

        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.getSuperior();  
			this.getUserWork(); 
            this.getUserTeam(); 
        },
        methods: {
            //我的团队（上级）
            async getSuperior() {
                const res = await getSuperior({
                    data: {
                        user_token: this.userToken
                    }
                });
                this.superior = res.data;
            },
            // 获取达人数据
            async getUserWork() {
                const res = await getUserWork({
                    data: {
                        user_token: this.userToken
                    }
                });
                this.teamInfo = res.data.user_team;
            },
            //我的团队列表
            async getUserTeam() {
                const load1 = this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0
                });
                let teamTypes = this.Tab,
                    serachType = this.searchType;
                const res = await getUserTeam({
                    data: {
                        user_token: this.userToken,
                        team_types: teamTypes,
                        pages: this.pages,
                        search_name: this.keyWords,
                        search_types: serachType
                    }
                });
                load1.clear()
                this.isLoad = true;
                if (res.code == "200") {
                    this.isLoad = true;
                    this.list = this.list.concat(res.data);
                    if (res.data.length < res.numPage) {
                        this.loadMore = false;
                    } else {
                        this.loadMore = true;
                    }
                }
            },
            getMore() {
                if (this.loadMore) {
                    this.pages++;
                    this.getUserTeam();
                }
            },
            // 切换直接掌柜 间接掌柜
            changeTab(tab) {
                this.Tab = tab;
                this.resetList();
            },
            // 重置列表参数
            resetList() {
                this.list = [];
                this.isLoad = false;
                this.loadMore = true;
                this.pages = 1;
                this.getUserTeam();
            },
            //重置
            resetKeyWords() {
                this.keyWords = "";
                this.searchType = 1;
                this.resetList();
            }
        },
        watch: {
            searchType(newV, lodV) {
                switch (newV) {
                    case 1:
                        this.inputType = "number";
                        break;
                    case 2:
                        this.inputType = "text";
                        break;
                }
            }
        },
        beforeDestroy() {
            this.$toast.clear();
        }
    };
</script>


<style lang="scss" scoped>
    .my-team {
        background: #f6f6f6;
        min-height: 100vh;

        .search-box {
            margin-bottom: 0.2rem;
            background-color: #fff;

            /deep/.van-search__content {
                background-color: #f6f6f6;
                border-radius: 0.1rem;
            }

            .right {
                display: flex;
                align-items: center;

                /deep/.van-dropdown-menu__bar {
                    box-shadow: none;
                    background-color: #f6f6f6;
                    height: 0.65rem;
                    border-radius: 0.1rem;
                    width: 1.4rem;

                    .van-dropdown-menu__title {
                        padding-left: 0;
                    }

                    .van-ellipsis {
                        font-size: 0.24rem;
                    }
                }

                /deep/.van-cell {
                    line-height: 0.88rem;

                    .van-cell__title {
                        padding-left: 0.2rem;
                    }
                }

                .reset {
                    background-color: #ff6b17;
                    border-radius: 0.1rem;
                    text-align: center;
                    width: 1rem;
                    height: 0.65rem;
                    line-height: 0.65rem;
                    color: #fff;
                    font-size: 0.24rem;
                    margin-left: 0.1rem;
                }
            }
        }

        .tab-box {
            padding: 0 0.24rem;
            box-sizing: border-box;
            width: 100%;
            height: 1.2rem;
            font-size: 0.28rem;
            background-color: #ffffff;
            display: flex;
            align-items: center;

            .tab {
                display: flex;
                align-items: center;
                justify-content: space-around;
                background-color: #f6f6f6;
                width: 100%;
                height: 0.68rem;
                border-radius: 0.34rem;

                .item {
                    width: 3rem;
                    height: 0.6rem;
                    line-height: 0.6rem;
                    text-align: center;
                    color: #b1b1b1;
                }

                .active {
                    background-color: #ff5400;
                    border-radius: 0.3rem;
                    color: #fff;
                }
            }
        }

        .search-box {
            padding: 0 0.24rem;
            padding-bottom: 0.2rem;

            .search {
                width: calc(100% - 1.3rem);
                height: 0.73rem;

                .van-cell {
                    line-height: 0.73rem;
                    padding: 0 0.2rem 0 0;
                }
            }

            .sbtn {
                border-radius: 0.1rem;
                font-size: 0.28rem;
                height: 0.73rem;
            }
        }
    }

    .my-team .top {
        background: #fff;
        padding: 0 3vw;
        margin-bottom: 0.2rem;
    }

    .my-team .top>span {
        font-size: 0.26rem;
        color: #c3c3c3;
        height: 55px;
        display: flex;
        align-items: center;
    }

    .my-team .top .info-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
    }

    .my-team .top .info-box img {
        width: 14vw;
        height: 14vw;
        border-radius: 50%;
    }

    .my-team .top .info-box .info {
        width: 76vw;
        height: 14vw;
        flex-direction: column;
        display: flex;
        justify-content: space-between;
        font-size: 0.26rem;
    }

    .my-team .top .info-box .info p {
        display: flex;
        justify-content: space-between;
    }

    .my-team .top .info-box .info .nickname {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 55%;
    }

    .my-team .top .info-box .info .time {
        color: #c3c3c3;
        font-size: 0.24rem;
    }

    .my-team .nav-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.3rem;
        margin-top: 0.2rem;
    }

    .my-team .nav-box a {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 50vw;
        height: 18vw;
        background: #fff;
        border-bottom: 1px solid #fff;
        margin-bottom: 15px;
        line-height: 1.8;
    }

    .my-team .nav-box a.ac {
        color: #ff5400;
        border-bottom: 1px solid #ff5400;
    }

    .team-grid {
        display: flex;
        align-items: flex-start;
        width: 100vw;
        padding: 3vw;
        margin-bottom: 15px;
        background: #fff;
        box-sizing: border-box;
    }

    .team-grid .img {
        width: 14vw;
        height: 14vw;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
    }

    .team-grid .nickname {
        font-size: 0.28rem;
        color: #000;
        margin-bottom: 10px;
    }

    .team-grid .nickname span {
        font-size: 0.24rem;
        color: #878787;
        margin-left: 15px;
    }

    .team-grid .name {
        font-size: 0.26rem;
        margin-bottom: 5px;
    }

    .team-grid .desc {
        font-size: 0.24rem;
        color: #878787;
    }

    .nomore {
        color: #c6c6c6;
        font-size: 0.24rem;
        line-height: 2;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;
    }
</style>
